export let CONFIG = {
    Settings: {
        SCOPES: ['People.Read'], // People.Read.All - Requires an Administrator to grant this permission
    }
};

/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { API_CONFIG } from '../environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: API_CONFIG.AzureB2C.SignUpSignIn,
        editProfile: API_CONFIG.AzureB2C.EditProfile,
        passwordReset: API_CONFIG.AzureB2C.PasswordReset
    },
    authorities: {
        signUpSignIn: {
            authority: API_CONFIG.AzureB2C.SignUpSignInAuthority,
        },
        editProfile: {
            authority: API_CONFIG.AzureB2C.EditProfileAuthority,
        },
        passwordReset: {
            authority: API_CONFIG.AzureB2C.PasswordResetAuthority,
        }

    },
    authorityDomain: API_CONFIG.AzureB2C.AuthorityDomain
};

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: API_CONFIG.AzureB2C.ClientId, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: `/`, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: `${window.location.origin}`, // Indicates the page to navigate after logout.
        
        // navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

export const protectedResources = {
    estore: {
        endpoint: API_CONFIG.ProtectEndpoints,
        scopes: API_CONFIG.AzureB2C.Scope,
        
    },
}
export const protectedResources2 = {
    estore: {
        endpoint: API_CONFIG.ProtectEndpoints2,
        scopes: API_CONFIG.AzureB2C.Scope,

    },
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */

export const loginRequest = {
    scopes: API_CONFIG.AzureB2C.Scope
};
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};

