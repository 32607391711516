import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import { ToastrService } from 'ngx-toastr';
import { AccountService, CommonService, InternationalizationService } from '../../../../services';
import { AccountRegistratinModel } from '../../../../models/account/account-registration.model';
import { RegistrationQueueModalComponent } from '../../../modals/registration-queue-modal/registration-queue-modal.component';
import { RegistrationApprovalModalComponent } from '../../../modals/registration-approve-modal/registration-approve-modal.component';
import { AgentNameComparatorComponent } from '../../../shared/datagrid-comparator/datagrid-comparator';
import {ClrDatagridSortOrder} from '@clr/angular';

@Component({
    selector: 'app-registration-queue-table',
    templateUrl: './registration-queue-table.component.html',
    styleUrls: ['./registration-queue-table.component.scss']
})

export class RegistrationQueueTableComponent implements OnInit, OnChanges {
    @Input() data: Array<AccountRegistratinModel> = [];
    dataFiltered: Array<AccountRegistratinModel> = [];
    dataSelected: Array<AccountRegistratinModel> = [];

    tableState = 0;

    regions: Array<any> = [];
    accounts: Array<any> = [];
    clients: Array<any> = [];

    selectedId: string;

    openRegistrationQueue: boolean;
    modalReference: ModalTransferModel;
    openRegistrationApproveModal: boolean;

    agentNameComparator = new AgentNameComparatorComponent();

    loadingDeny: boolean;
    descSort = ClrDatagridSortOrder.DESC;
    @Output() refreshData: EventEmitter<any> = new EventEmitter(); 

    @Input() loading: boolean;
    @Input() loadingAll: boolean;
    @Input() initLoading: boolean;

    constructor(private svc: AccountService,
        private toastr: ToastrService, private i18Svc: InternationalizationService,
        public common: CommonService) {
        this.i18Svc.initialize();
    }
    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes?.data) {
            this.dataFiltered = this.data || [];
          }
      }
      
    filter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.dataFiltered = this.data.filter(x => x.companyName?.toUpperCase().indexOf(filterValue) > -1
            || x.firstName?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.lastName?.toUpperCase().indexOf(filterValue) > -1
            || x.estimateYearlySales?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.email?.toUpperCase().indexOf(filterValue) > -1
            || x.phoneNumber?.toString()?.toUpperCase().indexOf(filterValue) > -1);
    }
    refresh() {
        if(!this.loadingAll) {
            this.refreshData.emit();
        }
    }
    approve() {
        this.openRegistrationApproveModal = true;
        this.modalReference = new ModalTransferModel('new', RegistrationApprovalModalComponent, this.dataSelected[0], null);
    }

    onRegistrationApproveModalClose() {
        this.openRegistrationApproveModal = false;
        this.refresh();
    }

    deny() {
        this.loadingDeny = true;
        this.svc.declineRegistreation(this.dataSelected[0].id).subscribe(dataFiltered => {
            this.loadingDeny = false;
            this.refresh();
            if (dataFiltered.result) {
                this.toastr.success('success!');
            }
        }, () => this.loadingDeny = false);
    }
    
    edit(): void {
        this.openRegistrationQueue = true;
        this.modalReference = new ModalTransferModel('edit', RegistrationQueueModalComponent, this.dataSelected[0], null);
    }

    onRegistrationQueueModalClose(_): void {
        this.openRegistrationQueue = false;
    }

      get isLoading(): boolean {
        return (this.loading || this.initLoading) && (this.data || [])?.length === 0;
      }
}
