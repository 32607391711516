import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/core/ResponseModel.model';
import { TopBannerModel } from '../models/marketing/topbanner.model';
import { BannerModel } from '../models/marketing/banner.model';

@Injectable()
export class MarketingService {


  constructor(private srv: CoreService) {
  }

    public getMarketingSlider(force: boolean): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/marketing/GetMarketingSlider?force=${force}`);
  }

  public disableSlider(id: number): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/marketing/DisableSlider?id=${id}`, {});
  }

  public createMarketingSlider(slider: TopBannerModel): Observable<ResponseModel<any>> {
    delete slider.versionIdString;
    return this.srv.post(`${this.srv.baseUrl}/marketing/CreateSlider/`, slider);
  }

  public updateMarketingSlider(slider: TopBannerModel): Observable<ResponseModel<any>> {
    delete slider.versionIdString;
    return this.srv.post(`${this.srv.baseUrl}/marketing/UpdateSlider/`, slider);
  }

  public uploadImageUrl(): string {
    return `${this.srv.baseUrl}/marketing/UploadImage/`;
  }

  public getBanner(id: number, force: boolean): Observable<ResponseModel<any>> {
      return this.srv.get(`${this.srv.baseUrl}/marketing/GetBanners/${id}?force=${force}`);
  }

  public disableBanner(id: number): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/marketing/DisableBanner?id=${id}`, {});
  }

  public createBottomBanner(slider: BannerModel): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/marketing/CreateBanner/`, slider);
  }

  public updateBottomBanner(slider: BannerModel): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/marketing/UpdateBanner/`, slider);
  }
  public GetAllVersionsAsOptions(id: number): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/product/GetAllVersionsAsOptions/${id}`);
  }
}
