import { Component, OnInit } from '@angular/core';
import { ReportModel } from '../../../../models/report/report.model';
import { InternationalizationService, ReportService, ExcelService, AccountService, CommonService } from '../../../../services';
import { AccountRegionModel } from '../../../../models/account.model';
import { ToastrService } from 'ngx-toastr';
import { ListItemModel } from '../../../../models/list-item.model';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-report-table',
    templateUrl: './report-table.component.html',
    styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit {
    reportsLoading = false;
    reports: ReportModel[] = [];
    reportsDataFiltered: ReportModel[] = [];
    startDateSelected: Date;
    endDateSelected: Date;

    regions: Array<AccountRegionModel> = [];
    accounts: Array<ListItemModel> = [];

    regionSelected: number;
    accountSelected: number;
    typeSelected = 'All';

     
    constructor(
        private reportSvc: ReportService,
        private i18Svc: InternationalizationService,
        private excelSvc: ExcelService,
        private accountSrv: AccountService,
        private toastr: ToastrService,
        public common: CommonService
        ) {
        this.i18Svc.initialize();
    }

    ngOnInit() {
        this.getRegions();
    }
      

    getRegions() {
        this.accountSrv.getAllRegions()
            .subscribe(data => this.onRegionGet(data), err => this.onGetError(err));
    }

    getAccounts(regionId: number) {
            this.accounts = [];
            this.accountSelected = 0;
            const req = regionId && regionId!=0 ? [regionId]: [];
            this.accountSrv.getAccountsAsListItemForRegions(req)
                .subscribe(data => this.onAccountGet(data), err => this.onGetError(err));
    }

    onRegionGet(data) {
        if (data.result) {
            this.regions = [];
            data.result.forEach(x => this.regions.push(AccountRegionModel.parse(x)));
            this.regionSelected = 0;
            this.getAccounts(0);
        } else {
            this.onGetError(data.errorMessage);
        }
    }

    onAccountGet(data) {
        if (data.result) {
            this.accounts = [];
            data.result.forEach(x => this.accounts.push(ListItemModel.Parse(x)));
        } else {
            this.onGetError(data.errorMessage);
        }
    }

    onGetError(data) {
        this.toastr.error('Error getting data');
    }

    getReports(): void {
        this.reports = [];
        this.reportsDataFiltered = [];
        this.reportsLoading = true;
        if (!this.typeSelected) {
            this.typeSelected = 'All';
        }
        switch (this.typeSelected.toString()) {
            case ('ESD'):
                console.log(this.startDateSelected);
                this.reportSvc.getReportsEsd(this.accountSelected, this.regionSelected, this.startDateSelected, this.endDateSelected)
                    .subscribe(data => this.onGetReports(data),
                        err => this.errorHandler(err));
                break;
            case ('OTK'):
                console.log(this.startDateSelected);
                this.reportSvc.getReportsOtk(this.accountSelected, this.regionSelected, this.startDateSelected, this.endDateSelected)
                    .subscribe(data => this.onGetReports(data),
                        err => this.errorHandler(err));
                break;
            default:
                console.log(this.startDateSelected);
                this.reportSvc.getReportsAll(this.accountSelected, this.regionSelected, this.startDateSelected, this.endDateSelected)
                    .subscribe(data => this.onGetReports(data),
                        err => this.errorHandler(err));
                break;
        }

    }

    onGetReports(data): void {
        const results = data['result'] || [];
        this.reports = results;
        this.reportsDataFiltered = results;
        this.reportsLoading = false;
    }

    reportFilter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.reportsDataFiltered = this.reports.filter(x =>
            x.id?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.status?.toUpperCase().indexOf(filterValue) > -1
            || x.accountName?.toUpperCase().indexOf(filterValue) > -1
            || x.accountEmail?.toUpperCase().indexOf(filterValue) > -1
            || x.refEmail?.toUpperCase().indexOf(filterValue) > -1
            || x.purchaseSku?.toUpperCase().indexOf(filterValue) > -1
            || x.internalSku?.toUpperCase().indexOf(filterValue) > -1
            || x.qty?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.regionName?.toUpperCase().indexOf(filterValue) > -1
            || x.soldCurrency?.name?.toUpperCase().indexOf(filterValue) > -1
            || x.purchaseCurrency?.name?.toUpperCase().indexOf(filterValue) > -1
            || x.invoiceQty?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.placedByName?.toUpperCase().indexOf(filterValue) > -1);
    }
    refreshReport(): void {
        this.getReports();
    }

    errorHandler(error) {
        console.log(error);
    }

    reportExport(): void {
        if (this.reportsDataFiltered.length > 0) {
            let data = [];
            let arrTitles = 
            [
                this.i18Svc.getTranslateVal('internal_id_text'),
                this.i18Svc.getTranslateVal('status_text'),
                this.i18Svc.getTranslateVal('acc_name_text'),
                this.i18Svc.getTranslateVal('acc_email_text'),
                this.i18Svc.getTranslateVal('ref_email_text'),
                this.i18Svc.getTranslateVal('purchase_SKU_text'),
                this.i18Svc.getTranslateVal('internal_SKU_text'),
                this.i18Svc.getTranslateVal('qty_text'),
                this.i18Svc.getTranslateVal('report.invoice_qty_text'),
                this.i18Svc.getTranslateVal('report_cpu_text'),
                this.i18Svc.getTranslateVal('report_clt_text'),
                this.i18Svc.getTranslateVal('report_ppu_text'),
                this.i18Svc.getTranslateVal('report_plt_text'),
                this.i18Svc.getTranslateVal('report_tlc_text'),
                this.i18Svc.getTranslateVal('report_tt_text'),
                this.i18Svc.getTranslateVal('report_cst_text'),
                this.i18Svc.getTranslateVal('discount_text'),
                this.i18Svc.getTranslateVal('total_text'),
                this.i18Svc.getTranslateVal('report_createdOn_text'),
                this.i18Svc.getTranslateVal('report_createdOn_text_time'),
                this.i18Svc.getTranslateVal('report_updatedOn_text'),
                this.i18Svc.getTranslateVal('report_updatedOn_text_time'),
                this.i18Svc.getTranslateVal('report.placedBy_name_text'),
                this.i18Svc.getTranslateVal('report_soldCurrency_text'),
                this.i18Svc.getTranslateVal('report_purchaseCurrency_text'),
                this.i18Svc.getTranslateVal('report_regionName_text')
            ];
            this.reportsDataFiltered.forEach(x =>{
                const obj={
                    id: x.id.toString(),
                    status:this.i18Svc.getTranslateVal(x.status),
                    accountName:x.accountName,
                    accountEmail:x.accountEmail,
                    refEmail:x.refEmail,
                    purchaseSku:x.purchaseSku,
                    internalSku:x.internalSku,
                    qty:x.qty,
                    invoiceQty: x.invoiceQty,
                    costPerUnit:x.costPerUnit,
                    costLineTotal:x.costLineTotal,
                    pricePerUnit:x.pricePerUnit,
                    priceLineTotal:x.priceLineTotal,
                    taxLineCalculated:x.taxLineCalculated,
                    taxTotal:x.taxTotal,
                    costSubTotal:x.costSubTotal,
                    discount:x.discount,
                    total:x.total,
                    createdOn: new DatePipe("en-US").transform( x.createdOn, 'yyyy-MM-dd'),
                    createdTimeOn: new DatePipe("en-US").transform( x.createdOn, 'HH:mm:ss'),
                    updatedOn:this.isDateValid(x.updatedOn.toString())? new DatePipe("en-US").transform(x.updatedOn.toString(), 'yyyy-MM-dd'):null,
                    updatedTimeOn:new DatePipe("en-US").transform(x.updatedOn, 'HH:mm:ss'),
                    placedByName: x.placedByName,
                    soldCurrency:x.soldCurrency.name,
                    purchaseCurrency:x.purchaseCurrency.name,
                    regionName:x.regionName
                }
                data.push(obj)
            });
            this.excelSvc.exportDocument('xls', data, arrTitles,'ESD_Report');
        }
    }

    get localeData() {
        if(localStorage.getItem('current_lang')?.toLowerCase() === 'fr') {
            return { locale: 'fr-FR', languageCode: 'fr' };
        }
        return { locale: 'en-US', languageCode: 'en' };
    }

    getDate(date: Date): string {
        return new DatePipe(this.localeData.locale).transform(date, 'mediumDate', undefined, this.localeData.locale);
    }

    getTime(date: Date): string {
        return new DatePipe(this.localeData.locale).transform(date, 'shortTime');
    }

    isDateValid(dateString: string): boolean {
        const date = new Date(dateString);
      
        // Check if the date is a valid instance of Date
        if (isNaN(date.getTime())) {
          return false;
        }
      
        // Check if the date string matches the parsed date
        // This additional check is useful to handle cases where the input date string is not in a recognized format
        const parsedDate = date.toISOString().split('T')[0];
        if (dateString !== parsedDate) {
          return false;
        }
      
        // Optionally, you can add more specific checks here
        // For example, you can validate the year range, month range, or day range
      
        return true;
      }

}
