import { Component, OnInit, Input,ViewEncapsulation, ChangeDetectorRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { SalesInvoiceListViewModel } from '../../../../models/billing/sales-invoice.model';
import { BillingSalesService } from '../../../../services/billing-sales.service';
import { InvoiceViewModalComponent } from '../../../modals/invoice-view-modal/invoice-view-modal.component';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import { ToastrService } from 'ngx-toastr';
import { CommonService, InternationalizationService } from '../../../../services';
import {ClrDatagridSortOrder} from '@clr/angular';

@Component({
  selector: 'app-orders-queue-table',
  templateUrl: './orders-queue-table.component.html',
  styleUrls: ['./orders-queue-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OrdersQueueTableComponent implements OnInit, OnChanges {
  @Input() data: Array<SalesInvoiceListViewModel> = [];
  dataFiltered: Array<SalesInvoiceListViewModel> = [];
  dataSelected: Array<SalesInvoiceListViewModel> = [];
  
  tableState = 0;

  regions: Array<any> = [];
  accounts: Array<any> = [];
  clients: Array<any> = [];
  loadingCount = 0;

  openInvoiceViewModal: boolean;
  modalReference: ModalTransferModel;
  descSort = ClrDatagridSortOrder.DESC;
  @Output() refreshData: EventEmitter<any> = new EventEmitter(); 

  @Input() loading: boolean;
  @Input() loadingAll: boolean;
  @Input() initLoading: boolean;

  constructor(private billingSvc: BillingSalesService, 
    private toastr: ToastrService, private i18Svc: InternationalizationService,private cdref: ChangeDetectorRef,
    public common: CommonService) {
    this.i18Svc.initialize();
  }
  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.data) {
      this.dataFiltered = this.data || [];
    }
  }

  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}

reloadLbl(){
       
    this.cdref.detectChanges();
}
  filter(filterValue: string) {
      filterValue = filterValue?.toUpperCase();
      this.dataFiltered = this.data.filter(x => x.accountName?.toUpperCase().indexOf(filterValue) > -1
      || x.accountNumber?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.accountCreditAvailable?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.id?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.invoiceGuid?.toUpperCase().indexOf(filterValue) > -1
      || x.items?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.cost?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.tax?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.total?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.createdOn?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.updateOn?.toString()?.toUpperCase().indexOf(filterValue) > -1);
  }
  refresh() {
    if(!this.loadingAll) {
      this.refreshData.emit();
    }
  }

  approve() {
    this.loadingCount = 0;
    this.tableState = 1;
    const ids = this.getSelectedIds(true);
    ids.forEach(x => {
      this.loadingCount++;
      this.billingSvc.approveOrder(x).subscribe(
        dataFiltered => this.onChangeStatus(dataFiltered, x),
        err => this.onChangeStatusError(err, x));
    });

    this.deselectAll();
  }
  deny() {
    this.loadingCount = 0;
    this.tableState = 1;
    const ids = this.getSelectedIds(true);
    ids.forEach(x => {
      this.loadingCount++;
      this.billingSvc.denyOrder(x).subscribe(
        dataFiltered => this.onChangeStatus(dataFiltered, x),
        err => this.onChangeStatusError(err, x));
    });
    this.deselectAll();
  }

  onChangeStatus(dataFiltered: any, id: number) {
    const msg = this.i18Svc.getTranslateVal('order-table_success_text');
    this.toastr.success(msg);
    this.loadingCount--;
    if (this.loadingCount === 0) {
      this.tableState = 2;
      this.refresh();
    }
  }

  onChangeStatusError(datdataFiltereda: any, id: number) {
    this.toastr.error(`Error while changing status for order ID:${id}`);
    this.loadingCount--;
  }

  edit(selected: SalesInvoiceListViewModel): void {
    this.openInvoiceViewModal = true;
    this.modalReference = new ModalTransferModel('edit', InvoiceViewModalComponent, selected.id, null);
  }

  onInvoiceViewModalClose(_): void {
    this.openInvoiceViewModal = false;
    this.refresh();
  }

  getSelectedIds(setToLoading: boolean): number[] {
    const selectedIs: number[] = [];
    this.dataSelected.forEach(x => {      
      selectedIs.push(x.id);
    });
    return selectedIs;
  }
  deselectAll() {
    this.dataSelected = [];
  }
  selectAll() {
    this.dataSelected = this.dataFiltered;
  }

  get isLoading(): boolean {
    return (this.loading || this.initLoading) && (this.data || [])?.length === 0;
  }
}
