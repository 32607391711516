import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ChannelService } from '../../../../services/channel.service';
import { ChannelModalComponent } from '../../../modals/channel-modal/channel-modal.component';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import { ChannelModel, Status } from '../../../../models/product/channel.model';
import { ProductService } from '../../../../services/product.service';
import { ProductModel } from '../../../../models/product/product.model';
import { ProductModalComponent } from '../../../modals/product-modal/product-modal.component';
import { PriceListModel } from '../../../../models/product/price-list/price-list.model';
import { PriceListEditModalComponent } from '../../../modals/price-list-edit-modal/price-list-edit-modal.component';
import { ProductVersionModel } from '../../../../models/product/product-version.model';
import { ProductVersionEditModalComponent } from '../../../modals/product-version-edit-modal/product-version-edit-modal.component';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { CommonService, InternationalizationService } from '../../../../services';
import { ClrDatagrid, ClrDatagridSortOrder } from '@clr/angular';

@Component({
  selector: 'app-product-admin',
  templateUrl: './product-admin.component.html',
  styleUrls: ['./product-admin.component.scss']
})

export class ProductAdminComponent implements OnInit {

  // Display variables
  collapseChannel = false;
  displayChannel = true;
  channelsLoading = false;

  collapseProducts = true;
  displayProducts = true;
  productsLoading = false;
  productTitle: string;

  collapseAddOns = false;
  displayAddOns = true;
  addOnsTitle: string;

  collapseBundlers = false;
  displayBundlers = true;

  //
  collapseVersions = false;
  versionsTableState = 0;
  displayVersions = true;
  versionTitle: string;

  // PriceList
  PriceListDisplayCollapse = false;
  priceListTableState = 0;

  // Data variables
  selectedChannel: ChannelModel;
  selectedProduct: ProductModel;
  selectedVersion: ProductVersionModel;

  selectedAddOnId: number;
  selectedBundlerId: number;

  products: ProductModel[] = [];
  productsDataFiltered: ProductModel[] = [];
  channels: ChannelModel[] = [];
  channelsDataFiltered: ChannelModel[] = [];

  addOns: any[] = [];
  bundlers: any[] = [];



  priceList: Array<PriceListModel> = [];
  priceListDataFiltered: Array<PriceListModel> = [];


  selectedPriceList: PriceListModel[] = [];

  collapseAddOn;
  regCodeDisplayCollapse;
  versionImport;
  statusList = Status;
  activeVersionsCount = 0;
  inactiveVersionsCount = 0;

  productActive: boolean;

  @ViewChild('container') container: ElementRef;

  openChannelModal: boolean;
  modalReference: ModalTransferModel;
  openDisableChannelModal: boolean;

  selectedChannelId: number;
  selectedProductId: number;

  openProductModal: boolean;
  openVersionEditModal: boolean;

  openPricelistEditModal: boolean;

  isDisableProduct: boolean;

  openStoreView: boolean;

  showInactiveProducts: boolean;
  showInactiveChannels: boolean;
  showInactivePriceSheets: boolean;

  hasPriceListDisable: boolean;
  hasPriceListEnable: boolean;
  ascSort = ClrDatagridSortOrder.ASC;

  readonly productSortName: string = 'product_sort_name';
  readonly productSortOrderName: string = 'product_sort_order';
  readonly channelSortName: string = 'channel_sort_name';
  readonly channelSortOrderName: string = 'channel_sort_order';
  readonly priceListSortName: string = 'price_list_sort_name';
  readonly priceListSortOrderName: string = 'price_list_sort_order';
  @ViewChild('productTable') productTable: ClrDatagrid;
  @ViewChild('channelTable') channelTable: ClrDatagrid;
  @ViewChild('priceListTable') priceListTable: ClrDatagrid;

  constructor(private channelSvc: ChannelService, private toastr: ToastrService,
    private productSvc: ProductService, private i18Svc: InternationalizationService, private cdref: ChangeDetectorRef,
    public common: CommonService) {
    this.i18Svc.initialize();
  }

  ngOnInit() {
    this.getChannels();
    this.getPriceList();
    this.selectedChannelId = null;
    this.refreshProducts();
  }
  ngDoCheck() {
    this.reloadLbl();
  }
  ngAfterViewInit() {
    this.reloadLbl();
  }
  reloadLbl() {

    this.cdref.detectChanges();
  }


  // General Functions
  errorHandler(err) {
    console.log(err);
  }
  // Get Services
  getChannels() {
    this.showChannels();
    this.channels = [];
    this.channelsDataFiltered = [];
    this.channelsLoading = true;
    this.channelSvc.getAll()
      .subscribe(data => this.onGetChannels(data),
        err => this.errorHandler(err));
  }

  onGetChannels(data) {
    const results = data['result'] || [];
    this.channels = results;
    this.channelsDataFiltered = results;
    this.onChannelTabChange();
    this.channelsLoading = false;
  }

  channelFilter(filterValue: string) {
    filterValue = filterValue?.toUpperCase();
    this.channelsDataFiltered = this.channels.filter(x => x.provider?.toUpperCase().indexOf(filterValue) > -1
      || x.engine?.toUpperCase().indexOf(filterValue) > -1
      || x.name?.toUpperCase().indexOf(filterValue) > -1
      || x.status?.toUpperCase().indexOf(filterValue) > -1
      || x.type?.toUpperCase().indexOf(filterValue) > -1
      || x.products?.toString()?.toUpperCase().indexOf(filterValue) > -1);
  }

  openProductsFromChannel() {
    this.productActive = true;
    if (this.selectedChannel) {
      this.selectedChannelId = this.selectedChannel?.id;
    }
    this.getProducts();
  }

  private scrollToBottom() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 1);
  }

  openVersionForProduct() {
    this.collapseProducts = true;
  }
  getProducts(isDefaultProducts?: boolean) {
    if (this.selectedChannel || isDefaultProducts) {
      this.showProducts();
      this.products = [];
      this.productsDataFiltered = [];
      this.productsLoading = true;
      this.productSvc.getProductByChannel(this.selectedChannel ? this.selectedChannel.id : 0)
        .subscribe(data => this.onGetProducts(data), err => this.errorHandler(err));
      if (isDefaultProducts) {
        this.productTitle = '';
      } else {
        this.productTitle = ` for ${this.selectedChannel.name} (${this.selectedChannel.id})`;
      }
    } else {
      this.productTitle = '';
    }
  }
  onGetProducts(data) {
    const result = data['result'] || [];
    this.products = result.map(x => ProductModel.parse(x));
    this.productsDataFiltered = result.map(x => ProductModel.parse(x));
    this.products.forEach(x => x.getVersions(this.productSvc)
      .subscribe(data => data, err => this.errorHandler(err))
    );
    this.sortDefaultColumn(this.productSortName, this.productSortOrderName, this.productTable, 'id');
    this.productsLoading = false;
  }

  onChannelTabChange() {
    setTimeout(() => {
      this.sortDefaultColumn(this.channelSortName, this.channelSortOrderName, this.channelTable, 'id');
    }, 1);
  }

  onPriceListTabChange() {
    setTimeout(() => {
      this.sortDefaultColumn(this.priceListSortName, this.priceListSortOrderName, this.priceListTable, 'Id');
    }, 1);
  }

  sortDefaultColumn(sortName: string, sortOrderName: string, tableDatagrid: ClrDatagrid, defaultName: string) {
    const productColumnProperties: any = this.common.getDatagridColumnSortProperties(sortName, sortOrderName, defaultName) || {};
    tableDatagrid?.columns?.forEach(x => {
      if (x.field === productColumnProperties?.name) {
        x.sort(productColumnProperties?.order === -1);
      }
    });
  }

  sortOrderChange(order: number, name: string, sortName: string, sortOrderName: string) {
    if (order !== 0) {
      localStorage.setItem(sortName, name);
      localStorage.setItem(sortOrderName, JSON.stringify(order));
    }
  }

  sortProductTable(order: number, name: string) {
    this.sortOrderChange(order, name, this.productSortName, this.productSortOrderName);
  }

  sortChannelTable(order: number, name: string) {
    this.sortOrderChange(order, name, this.channelSortName, this.channelSortOrderName);
  }

  sortPriceListTable(order: number, name: string) {
    this.sortOrderChange(order, name, this.priceListSortName, this.priceListSortOrderName);
  }

  productFilter(filterValue: string) {
    filterValue = filterValue?.toUpperCase();
    this.productsDataFiltered = this.products.filter(x => x.channelName?.toUpperCase().indexOf(filterValue) > -1
      || x.channelTypeName?.toUpperCase().indexOf(filterValue) > -1
      || x.name?.toUpperCase().indexOf(filterValue) > -1
      || x.defaultVersionName?.toUpperCase().indexOf(filterValue) > -1
      || x.versions?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.tags?.toString()?.toUpperCase().indexOf(filterValue) > -1)
  }

  // General UI Fuctions

  // Channel UI Functions
  showChannels(): void {
    this.collapseChannel = false;
    this.displayChannel = true;
  }
  selectChannel(scid: ChannelModel): void {
    if (this.selectedChannel === scid) {
      this.selectedChannel = null;
    } else {
      this.selectedChannel = scid;
    }
  }
  closeChannel() {
    this.displayChannel = false;
    this.channels = null;
    this.channelsDataFiltered = null;
  }
  refreshChannel() {
    this.collapseChannel = false;
    this.getChannels();
  }
  channelEdit() {
    this.openChannelModal = true;
    this.modalReference = new ModalTransferModel('open', ChannelModalComponent, this.selectedChannel, null, null);
  }
  channelAdd() {
    this.openChannelModal = true;
    this.modalReference = new ModalTransferModel('new', ChannelModalComponent, null, null, null);
  }

  onChannelModalClose(refresh: boolean): void {
    this.openChannelModal = false;
    if (refresh) {
      this.refreshChannel();
    }
  }

  // Product UI Functions
  showProducts(): void {
    this.collapseProducts = false;
    this.displayProducts = true;
  }
  selectProduct(spid: ProductModel): void {
    if (this.selectedProduct === spid) {
      this.selectedProduct = null;
      this.closeVersions();
      this.versionTitle = '';
    } else {
      this.selectedProduct = spid;
    }
  }
  closeProducts() {
    this.displayProducts = false;
    this.products = null;
    this.productsDataFiltered = null;
  }
  refreshProducts() {
    this.collapseProducts = false;
    this.getProducts(!this.selectedChannel);
  }

  onChannelChange(): void {
    this.selectedChannel = this.channels.find(x => x.id === this.selectedChannelId);
    this.refreshProducts();
  }

  productEdit(product: ProductModel) {
    this.openProductModal = true;
    this.modalReference = new ModalTransferModel('open', ProductModalComponent, product, null, null);
  }
  productAdd() {
    this.openProductModal = true;
    this.modalReference = new ModalTransferModel('new', ProductModalComponent, this.selectedChannel, null, null);
  }

  onProductModalClose(refresh: boolean): void {
    this.openProductModal = false;
    if (refresh) {
      this.refreshProducts();
    }
  }

  // Version UI
  showVersions(): void {
    this.collapseVersions = false;
    this.displayVersions = true;
  }
  closeVersions() {
    this.displayVersions = false;
  }
  refreshVersions() {
    this.collapseVersions = false;
  }

  openDefaultVersion(product: ProductModel): void {
    this.scrollToBottom();
    this.productSvc.getVersionsForProduct(product.id)
      .subscribe(data => {
        if (data.result) {
          let selected = data.result.find(x => x.id === product.defaultVersionId);
          this.openVersionEdit(selected);
        }
      });
  }

  versionEdit(isOpenDefaultVersion?: boolean) {
    let selected: any = [];
    if (isOpenDefaultVersion) {
      this.productSvc.getVersionsForProduct(this.selectedProduct.id)
        .subscribe(data => {
          if (data.result) {
            selected = data.result.find(x => x.id === this.selectedProduct.defaultVersionId);
            this.openVersionEdit(selected);
          }
        });
    } else {
      selected = JSON.parse(JSON.stringify(this.selectedVersion));
      this.openVersionEdit(selected);
    }
  }

  openVersionEdit(selected) {
    this.openVersionEditModal = true;
    this.modalReference = new ModalTransferModel('edit', ProductVersionEditModalComponent, selected, null);
  }

  versionAdd(product: ProductModel) {
    this.openVersionEditModal = true;
    const object = { channelId: this.selectedChannel.id, productId: product ? product.id : '' };
    this.modalReference = new ModalTransferModel('new', ProductVersionEditModalComponent, object, null);
  }


  onVersionModalClose(): void {
    this.openVersionEditModal = false;
  }

  // Price List
  getPriceList() {
    this.priceListTableState = 1;
    this.priceList = [];
    this.priceListDataFiltered = [];
    this.productSvc.getAllPriceList()
      .subscribe(
        data => this.onGetAllPriceList(data),
        err => this.errorHandler(err));
  }
  onGetAllPriceList(data) {
    if (data['result']) {
      this.priceList = [];
      this.priceListDataFiltered = [];
      this.priceListTableState = 2;
      data['result'].forEach(x => {
        this.priceList.push(PriceListModel.Parse(x));
        this.priceListDataFiltered.push(PriceListModel.Parse(x))
      });
      this.onPriceListTabChange();
    } else {
      this.priceListTableState = 4;
    }
  }
  priceListCollapseToggle() {
    this.PriceListDisplayCollapse = !this.PriceListDisplayCollapse;
  }
  priceListEdit() {
    const selected = JSON.parse(JSON.stringify(this.selectedPriceList[0]));
    this.openPricelistEditModal = true;
    this.modalReference = new ModalTransferModel('edit', PriceListEditModalComponent, selected, null);
  }
  priceListAdd() {
    this.openPricelistEditModal = true;
    this.modalReference = new ModalTransferModel('new', PriceListEditModalComponent, null, null);
  }
  priceListCopy() {
    const copy = JSON.parse(JSON.stringify(this.selectedPriceList[0]));
    this.openPricelistEditModal = true;
    this.modalReference = new ModalTransferModel('copy', PriceListEditModalComponent, copy, null);
  }

  onPricelistEditModalClose(_): void {
    this.openPricelistEditModal = false;
  }

  priceListRefresh() {
    this.getPriceList();
  }
  priceListDisable() {
    this.priceListStatusChange(false);
  }
  priceListEnable() {
    this.priceListStatusChange(true);
  }
  priceListStatusChange(isActive: boolean) {
    this.selectedPriceList.forEach(x => {
      if (x.IsActive !== isActive) {
        x.IsActive = isActive;
        x.SetLoading(true);
        this.productSvc.updatePriceList(x).subscribe(
          data => this.onPriceListStatusUpdate(data, x),
          err => this.onPriceListStatusUpdateError(err, x));
      }
    });
  }
  onPriceListStatusUpdate(data, row: PriceListModel) {
    row.SetLoading(false);
    const isLoading = this.selectedPriceList.some(x => x.IsLoading());
    if (!isLoading) {
      this.priceListRefresh();
    }
  }
  onPriceListStatusUpdateError(err, row: PriceListModel) {
    row.SetLoading(false);
    this.onError('Something went wrong');
  }

  priceListFilter(filterValue: string) {
    filterValue = filterValue?.toUpperCase();
    this.priceListDataFiltered = this.priceList.filter(x => x.Name?.toUpperCase().indexOf(filterValue) > -1
      || x.Currency?.toUpperCase().indexOf(filterValue) > -1
      || x.Priority?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.Products?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.UsedBy?.toString()?.toUpperCase().indexOf(filterValue) > -1);
  }


  disableChannel(): void {
    this.isDisableProduct = false;
    this.onDisable();
  }

  onDisable(): void {
    this.openDisableChannelModal = true;
  }

  onDisableModalClose(refresh: boolean): void {
    this.openDisableChannelModal = false;
    if (refresh) {
      if (!this.isDisableProduct) {
        this.onDisableChannel();
      }
    }
  }

  onDisableProduct(): void {
    this.productsLoading = true;
  }

  onDisableChannel(): void {
    this.channelsLoading = true;
    this.channelSvc.disableChannel(this.selectedChannel.id).subscribe(data => this.onGetEnableResult(data),
      err => this.showError(err));
  }

  onEnableChannel(): void {
    this.channelsLoading = true;
    this.channelSvc.enableChannel(this.selectedChannel.id).subscribe(data => this.onGetEnableResult(data),
      err => this.showError(err));
  }

  enableProduct(product: ProductModel): void {
    this.productStatusChange(product, true);
  }

  disableProduct(product: ProductModel): void {
    this.productStatusChange(product, false);
  }
  productStatusChange(product: ProductModel, isActive: boolean) {
    this.productsDataFiltered.forEach(x => {
      if (product?.id === x.id && x.isActive !== isActive) {
        x.isActive = isActive;
        this.productSvc.setProductStatus(x, isActive).subscribe(
          data => this.onProductStatusUpdate(data, x),
          err => this.onProductStatusUpdateError(err, x));
      }
    });
  }
  onProductStatusUpdate(data, row: ProductModel) {
    this.refreshProducts();
  }
  onProductStatusUpdateError(err, row: ProductModel) {
    this.onError('Something went wrong');
  }
  checkChannelStatus(): void {
    this.channelSvc.checkChannelStatus(this.selectedChannel.id).subscribe(data => {
      this.showSuccess(data['result']);
    });
  }

  onGetEnableResult(data): void {
    this.channelsLoading = false;
    if (data['result']) {
      this.selectedChannel.isActive = !this.selectedChannel.isActive;
      this.refreshChannel();
    } else {
      this.showError(data['errorMessage']);
    }
  }

  showSuccess(msg): void {
    this.toastr.success(msg);
  }

  showError(err): void {
    this.channelsLoading = false;
    this.toastr.error(err);
  }

  onError(msg) {
    swal({
      position: 'top-end',
      type: 'error',
      title: 'Oops...',
      text: msg,
      footer: '<a href>Why do I have this issue?</a>'
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSelectedTabChange(): void {
    if (!this.selectedChannel) {
      this.getProducts(true);
    } else {
      this.selectedChannelId = this.selectedChannel?.id;
    }
  }

  onProductDataChange(e): void {
    console.log(e);
  }

  openProductView() {
    this.openStoreView = true;
  }

  closeProductView() {
    this.openStoreView = false;
  }
  checkESD(product: ProductModel) {
    const flag = product.channelName || false;
    if (flag) {
      if (product.channelName.includes('-ESD')) {
        return true;
      }
      else {
        return false;
      }

    } else {
      return false;
    }

  }

  priceListRowStatusClass(priceList: PriceListModel): string {
    if (priceList?.IsActive) {
      if (priceList?.UsedBy && priceList?.Products) {
        return 'itemActive';
      }
      return 'itemGray';
    }
    return 'itemInactive';
  }

  onPriceListSelectionChange(): void {
    this.hasPriceListDisable = false;
    this.hasPriceListEnable = false;
    this.selectedPriceList.forEach(x => {
      if (x.IsActive) {
        this.hasPriceListEnable = true;
      } else {
        this.hasPriceListDisable = true;
      }
    });
  }

}

