import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { AccountModel, AccountRegionModel } from '../models/account.model';
import { ResponseModel } from '../models/core/ResponseModel.model';

@Injectable()
export class AccountService {

  constructor(private srv: CoreService) {

  }

  public get(_id: number): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/account/get/` + _id);
  }

  public getForRegion(_id: number): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/account/get/` + _id);
  }

  public getAllRegions(): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/accountregion/getall/`);
  }
  public getRegion(id: number): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/AccountRegion/get/${id}`);
  }
  public getAllAccountsAsListItem(): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/account/GetAllAccountsAsListItem/`);
  }

  public getAllRegionsAsListItem(): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/account/GetAllRegionsAsListItem/`);
  }

  public regionCreate(model: AccountRegionModel): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/accountregion/create/`, model);
  }
  public regionUpdate(model: AccountRegionModel): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/accountregion/update/`, model);
    }
    public completeRegistration(id: string): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/account/CompleteRegistration/${id}`, null);
    }
    
  public getAccountsAsListItemForRegions(_regionIds: number[]): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/account/getForAccountsAsListItem/`, _regionIds);
  }

  public create(model: AccountModel): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/account/create`, model);
  }

  public update(model: AccountModel): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/account/update`, model);
  }

  public getRegistrationByRegion(regionId: number): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/account/GetRegistrationByRegion/${regionId}`);
  }

  public declineRegistreation(id: string): Observable<ResponseModel<any>> {
      return this.srv.post(`${this.srv.baseUrl}/account/DeclineAccountRegistreation?id=${id}`, {});
  }

  public getApi(accountId: string): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/account/getApi/${accountId}`);
  }

  public enableApi(accountId: string): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/account/enableApi/${accountId}`, null);
  }

  public disableApi(accountId: string): Observable<ResponseModel<any>> {
    return this.srv.post(`${this.srv.baseUrl}/account/disableApi/${accountId}`, null);
  }

  

}
