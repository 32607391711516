import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccountComponent } from './account/account.component';
import { AccountRegionTableComponent } from './account/account-region-table/accounts-region-table.component';
import { FilterPipe } from './pipes/filter.pipe';
import { CurrencySymbolPipe } from './pipes/currencySymbol.pipe';
import { MsalGuard } from '@azure/msal-angular';



//CLARITY 
import '@cds/core/icon/register.js';
import { ClarityModule } from '@clr/angular';

// Modals
import {
    NewClientModalComponent, ClientDetailsModalComponent,
    InvoiceModalComponent, InvoiceViewModalComponent,
    ChannelModalComponent, ProductModalComponent, 
    UserEditModalComponent, PriceListEditModalComponent, ProductVersionEditModalComponent,
    UserTemplateModalComponent, LicenseOrderTableComponent,
    WarningModalComponent, TopBannerTableModalComponent, BannerTableModalComponent, ProductAlertModalComponent,
    RegistrationQueueModalComponent, CreditManagementComponent, ApiManagementComponent, VersionDetailModalComponent,
    TokenTableComponent, RegistrationApprovalModalComponent, UserImportComponent, UserImportEditComponent,
    AuditModalComponent
} from '../../modules/modals';
import { CurrencyModalComponent } from '../main';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { CheckboxControlComponent } from '../../modules/controls/checkbox-control/checkbox-control.component';
import { DdlControlComponent } from '../controls/ddl-control/ddl-control.component';
import { OrderByPipe } from './pipes/order-by-pipe.pipe';
import { DragulaModule } from 'ng2-dragula';
import { ActiveFilterPipe } from './pipes/active-filter.pipe';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchComponent } from './search/search.component';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { InternalUserComponent } from './user-management/internal-user/internal-user.component';
import { ResellerUserComponent } from './user-management/reseller-user/reseller-user.component';
import { UsersManagementComponent } from '../modals/users-management/users-management.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ProgressLoaderComponent } from './progress-loader/progress-loader.component';
import { PricelistAvailableProductsModalComponent } from '../modals/pricelist-available-products-modal/pricelist-available-products-modal.component';
import { ImagePreloadDirective } from '../../directives/image-error-directive';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ClarityModule,
        DropzoneModule,
        MonacoEditorModule,
        DragulaModule,
        RouterModule.forChild([
            {
                path: 'accountManagement', component: AccountRegionTableComponent, canActivate: [MsalGuard], data: { title: 'Account' }
            },
            {
                path: 'search', component: SearchComponent, canActivate: [MsalGuard], data: { title: 'Search' }
            },
            {
                path: 'user', component: UserManagementComponent, canActivate: [MsalGuard], data: { title: 'User Management', Id: 1 }
            }
        ]),
        
        NgbModule
    ],
    declarations: [AccountComponent, AccountRegionTableComponent, FilterPipe, CurrencySymbolPipe,
        NewClientModalComponent,
        UserTemplateModalComponent,
        ClientDetailsModalComponent,
        InvoiceModalComponent,
        InvoiceViewModalComponent,
        ChannelModalComponent,
        ProductModalComponent,
        UserEditModalComponent,
        PriceListEditModalComponent,
        ProductVersionEditModalComponent,
        LicenseOrderTableComponent,
        WarningModalComponent,
        TopBannerTableModalComponent,
        BannerTableModalComponent,
        ProductAlertModalComponent,
        RegistrationQueueModalComponent,
        CreditManagementComponent,
        ApiManagementComponent,
        CurrencyModalComponent,
        VersionDetailModalComponent,
        TokenTableComponent,
        RegistrationApprovalModalComponent,
        CheckboxControlComponent,
        DdlControlComponent,
        OrderByPipe,
        ActiveFilterPipe,
        SearchComponent,
        GenericTableComponent,
        UserImportComponent,
        UserImportEditComponent,
        UserManagementComponent, InternalUserComponent,
        ResellerUserComponent,
        UsersManagementComponent,
        ProgressLoaderComponent,
        PricelistAvailableProductsModalComponent,
        AuditModalComponent,
        ImagePreloadDirective
    ],
    exports: [AccountComponent, AccountRegionTableComponent, FilterPipe, CurrencySymbolPipe, ClarityModule,
        NewClientModalComponent,
        UserTemplateModalComponent,
        ClientDetailsModalComponent,
        InvoiceModalComponent,
        InvoiceViewModalComponent,
        ChannelModalComponent,
        ProductModalComponent,
        UserEditModalComponent,
        PriceListEditModalComponent,
        ProductVersionEditModalComponent,
        LicenseOrderTableComponent,
        WarningModalComponent,
        TopBannerTableModalComponent,
        BannerTableModalComponent,
        ProductAlertModalComponent,
        RegistrationQueueModalComponent,
        CreditManagementComponent,
        ApiManagementComponent,
        UsersManagementComponent,
        CurrencyModalComponent,
        VersionDetailModalComponent,
        TokenTableComponent,
        RegistrationApprovalModalComponent,
        CheckboxControlComponent,
        DdlControlComponent,
        OrderByPipe,
        DragulaModule,
        ActiveFilterPipe,
        UserImportComponent,
        UserImportEditComponent,
        GenericTableComponent,
        ProgressLoaderComponent,
        PricelistAvailableProductsModalComponent,
        AuditModalComponent,
        ImagePreloadDirective
    ]
})
export class SharedModule { }
