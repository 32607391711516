import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductVersionImageModel, ProductVersionModel } from '../../../models/product/product-version.model';
import { TokenOtkViewModel } from '../../../models/tokens/token.model';
import { CommonService, ProductService } from '../../../services';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-token-table',
  templateUrl: './token-table.component.html',
  styleUrls: ['./token-table.component.scss']
})
export class TokenTableComponent implements OnInit {
  
  tokenLoading: boolean;
  @Input() model: ProductVersionModel;
  tokens: Array<TokenOtkViewModel> = [];
  tokensOriginal: Array<TokenOtkViewModel> = [];

  @Input() selectedImage: ProductVersionImageModel;
  @Output() countChanged: EventEmitter<number> =   new EventEmitter();
   

  constructor(
    private prodSrv: ProductService,
     private toastr: ToastrService,
     private cdref: ChangeDetectorRef,
     public common: CommonService
     ) { }

  ngOnInit(): void {
    this.getTokens();
  }

  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}
reloadLbl(){
       
    this.cdref.detectChanges();
}

  getTokens() {
    this.tokenLoading = true;
    this.prodSrv.getTokensByVersion(this.model.id).subscribe(
      data => this.onGetTokens(data),
      err => this.onGetTokensError(err));
  }

  onGetTokens(data) {
    this.tokenLoading = false;
    if (data['result']) {
      this.tokens = [];
      this.tokensOriginal = [];
      data['result'].forEach(x => {
        this.tokens.push(TokenOtkViewModel.parse(x));
        this.tokensOriginal.push(TokenOtkViewModel.parse(x));
      });
    }
  }
  
  onGetTokensError(err) {
    this.tokenLoading = false;
    this.onError(err);
  }

  onError(_) {
    this.toastr.error('Something went wrong!');
  }

  onTokenFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const dataUpload = <any[]>(XLSX.utils.sheet_to_json(ws, { header: ['token', 'link', 'expiration'] }));
      const parseData: Array<TokenOtkViewModel> = [];
      for (let i = 1; i < dataUpload.length; i++) {
        parseData.push(new TokenOtkViewModel(0, this.model.id, null, this.model.channelId,
          dataUpload[i]['link'],
          dataUpload[i]['token'],
          dataUpload[i]['expiration'], true, new Date(), null, null,null));
      }

      this.prodSrv.uploadOtk(parseData).subscribe(data => this.onUploadOtk(data),
        err => this.onError(err));
    };
    reader.readAsBinaryString(target.files[0]);
  }

  onUploadOtk(data) {
    this.getTokens();
  }
  openInvoice(id){
    this.countChanged.emit(id);
  }

}
