
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import { ConfigurationService } from '../../../../services/configuration.service';
import swal from 'sweetalert2';
import { TaxModel, TaxGroupModel } from '../../../../models/billing/tax.model';
import { AuthService, CommonService, InternationalizationService } from '../../../../services';

@Component({
    selector: 'app-taxmodal-modal',
    templateUrl: './tax-modal.component.html',
    styleUrls: ['./tax-modal.component.scss']
})
/** TaxModal component*/
export class TaxModalComponent implements OnInit {
    @Input() reference: ModalTransferModel;

    //dataSource: MatTableDataSource<TaxModel>;


    model: TaxGroupModel;
    selectedTax: TaxModel[] = [];

    editMode: boolean;
    isNew: boolean;
    title: string;
    canRemove = false;
    loading = false;
    newTax: TaxModel;
    displayedColumns = ['id', 'name', 'percentage'];
    tableState = 0;
    selectCount = 0;

    @Input() set open(open: boolean) {
        this._open = open;
      }
      get open(): boolean {
        return this._open;
      }
      private _open: boolean;

    @Output() modalClose = new EventEmitter();
     

    constructor(public activeModal: NgbActiveModal, private srv: ConfigurationService, private auth: AuthService,
        private i18Svc: InternationalizationService,
        public common: CommonService) {
            this.i18Svc.initialize();
         }
    public canView = this.auth.canViewTax;
    public canEdit = this.auth.canEditTax;

    ngOnInit() {
        this.initMode();
    }

      

    initMode() {
        // Set Mode
        this.isNew = !this.reference || !this.reference.object;
        this.editMode = this.isNew;

        // Set Model
        if (!this.isNew) {
            this.model = TaxGroupModel.parse(this.reference.object);
            this.tableState = 2;
        } else {
            this.model = TaxGroupModel.create();
            this.reference.object = this.model;
        }

        // Set Title
        if (this.isNew) {
            this.title = 'Add Tax Group';
        } else {
            this.title = 'Tax Group: ' + this.model.name;
        }
    }

    handleError(err) {
        console.log(err);
        this.loading = false;
        swal(' Ops...', ' Something went wrong', 'error');
    }

    validModel(): boolean {
        return true;
    }
    cancel() {
        this.editMode = false;
    }
    goEditMode() {
        this.editMode = true;
    }
    addTax() {
        this.newTax = TaxModel.create(this.model.id);
    }
    pushTax() {
        this.model.taxes.push(this.newTax);
        this.newTax = null;
    }
    removeTax() {
        this.model.taxes = this.model.taxes.filter(x => this.selectedTax.indexOf(x) === -1);
    }
    save() {
        if (this.validModel()) {
            this.loading = true;
            if (this.isNew) {
                this.srv.taxGroupsCreate(this.model)
                    .subscribe(data => this.onResponse(data),
                        err => this.handleError(err));
            } else {
                this.srv.taxGroupsUpdate(this.model)
                    .subscribe(data => this.onResponse(data),
                        err => this.handleError(err));
            }
        }
    }
    onResponse(data) {
        if (!this.reference) {
            this.reference = new ModalTransferModel('open', TaxModalComponent, null, null);
        }
        this.model = TaxGroupModel.parse(data['result']);
        this.reference.object = this.model;
        this.initMode();
        this.cancel();
        this.closeModal(true);
        this.loading = false;
    }
    close(swal: boolean = false, success: boolean = true) {
        this.reference.close();
        if (swal) {
            if (success) {
                this.reference.setSuccess('Tax Group Added', 'your tax group have been added: ' + this.model.name);
            } else {
                this.reference.setError('Tax Group Error', 'failed to add tax group: ' + this.model.name);
            }
        }
        this.closeModal();
    }

    closeModal(refresh?: boolean): void {
        this.open = false;
        this.modalClose.emit(refresh);
    }

}
