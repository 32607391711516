import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { ResponseModel } from '../models/core/ResponseModel.model';
import { API_CONFIG } from '../../environments/environment';

@Injectable()
export class CoreService {
  constructor(private http: HttpClient) {

  }
  public baseUrl = API_CONFIG.CORE_API;
  public storeUrl = API_CONFIG.STORE_API;
  public manageURL = API_CONFIG.ACCOUNTMANAGEMENT_API2;
  public identityUrl = API_CONFIG.IDENTITY_API;

  private createAuthorizationHeader() {
    const token = localStorage.getItem('jwt');
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token, content-type');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
    headers.append('Access-Control-Allow-Origin', '*'); // TODO: this needs to be configure

    return headers;
  }

  private formatErrors(error: any) {
    console.log(error.error);
    return throwError(error);
  }

  public get(url: string): Observable<ResponseModel<any>> {
    const headers = this.createAuthorizationHeader();
    return this.http.get<ResponseModel<any>>(url, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }

  public post(url: string, obj: any): Observable<ResponseModel<any>> {
    const headers = this.createAuthorizationHeader();
    return this.http.post<ResponseModel<any>>(url, obj, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }
  public postProgress(url: string, obj: any): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.http.post(url, obj, { headers: headers, reportProgress: true })
      .pipe(catchError(this.formatErrors));
  }
  public put(url: string, obj: any): Observable<ResponseModel<any>> {
    const headers = this.createAuthorizationHeader();
    return this.http.put<ResponseModel<any>>(url, obj, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }
  public delete(url: string): Observable<ResponseModel<any>> {
    const headers = this.createAuthorizationHeader();
    return this.http.delete<ResponseModel<any>>(url, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }
}
