import { Component, EventEmitter, Input, OnInit, Output, ViewChildren, ViewChild, ElementRef, QueryList } from '@angular/core';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { ListItemModel } from '../../../models/list-item.model';
import { PriceListItemModel, PriceListModel } from '../../../models/product/price-list/price-list.model';
import { ProductService } from '../../../services/product.service';
import { ConfigurationService } from '../../../services/configuration.service';
import swal from 'sweetalert2';
import { ExcelService, InternationalizationService } from '../../../services';
import * as XLSX from 'xlsx';
import { NgForm } from '@angular/forms';
import { PricelistAvailableProductsModalComponent } from '../pricelist-available-products-modal/pricelist-available-products-modal.component';


@Component({
    selector: 'app-price-list-edit-modal',
    templateUrl: './price-list-edit-modal.component.html',
    styleUrls: ['./price-list-edit-modal.component.scss']
})

export class PriceListEditModalComponent implements OnInit {
    @Input() name: string;
    @Input() id: number;
    @Input() reference: ModalTransferModel;

    model: PriceListModel;
    selectedNav = 1;
    title: string;
    currencyOptions: Array<ListItemModel> = [];
    auxid: string;
    auxNumba: string;

    products: Array<any> = [];
    addOns: Array<PriceListItemModel> = [];

    @Input() set open(open: boolean) {
        this._open = open;
    }
    get open(): boolean {
        return this._open;
    }
    private _open: boolean;

    @Output() modalClose = new EventEmitter();
    @ViewChildren('inputPrice') inputPrice: QueryList<HTMLInputElement>;
    @ViewChildren('th1') titles: QueryList<HTMLInputElement>;
    @ViewChild('file') file!: ElementRef;

    openPriceListAvailable: boolean;
    modalReference: ModalTransferModel;
    loading: boolean;

    constructor(private prodSrv: ProductService,
        private confSrv: ConfigurationService, private excelSvc: ExcelService, private i18Svc: InternationalizationService
        , private exportService: ExcelService
    ) {
        this.i18Svc.initialize();
    }

    ngOnInit(): void {
        this.getCurrencies();
        this.initAction();
    }
    initAction() {
        if (this.reference.action === 'copy') {
            this.model = PriceListModel.Cast(this.reference.object);
            this.model.Name = `${this.model.Name}-Copy`;
            this.title = `Cloning ${this.model.Name}`;
            this.getPriceListItems(this.model.Id);
            this.model.Id = 0;
        } else if (this.reference.action === 'new') {
            this.model = PriceListModel.Create();
            this.title = 'New Price List';
            this.setDefaultCurrency();
            this.getPriceListAvailableItems();
        } else if (this.reference.action === 'edit') {
            this.model = PriceListModel.Cast(this.reference.object);
            this.title = `Edit ${this.model.Name} (${this.model.Id})`;
            this.getPriceListItems(this.model.Id);
        }
    }
    setDefaultCurrency() {
        if (!this.reference.object && this.currencyOptions.length > 0 && this.model) {
            this.model.CurrencyId = this.currencyOptions[0].id;
            this.model.Currency = this.currencyOptions[0].value;
        }
    }
    getCurrencies() {
        this.confSrv.getCurrencyOptions().subscribe(
            data => { this.onGetCurrencies(data) },
            err => this.onError(err));
    }
    onGetCurrencies(data) {
        if (data['result']) {
            this.currencyOptions = [];
            data['result'].forEach(x =>
                this.currencyOptions.push(ListItemModel.Parse(x)));
        }
        this.setDefaultCurrency();
    }
    getPriceListAvailableItems() {
        this.prodSrv.getPriceListAvailableItems().subscribe(
            data => this.onGetPriceListItems(data),
            err => this.onError(err));
    }
    getPriceListItems(id: number) {
        this.prodSrv.getPriceListItems(id).subscribe(
            data => this.onGetPriceListItems(data),
            err => this.onError(err));
    }
    updatePriceListItems() {
        if (this.products.length > 0) {
            this.prodSrv.updatePriceListItems(this.products.concat(this.addOns)).subscribe(
                data => this.onGetPriceListItems(data),
                err => this.onError(err));
        }
    }
    getAvailProductVersionItems(id: number) {
        this.prodSrv.getProductVersionAvailableItems(id).subscribe(
            data => this.onGetPriceListItems(data),
            err => this.onError(err));
    }
    onGetPriceListItems(data) {
        if (data['result']) {
            this.products = [];
            this.addOns = [];
            data['result'].forEach(x => {
                if (x) {
                    this.splitProds(PriceListItemModel.Parse(x));
                }
            });
        }
    }
    splitProds(model: PriceListItemModel) {
        if (model.ProductVersionId) {
            const mod = { ...model, percent: this.getPercent(model.Price, model.DiscountPrice), hasError: false, isTouched: false }
            this.products.push(mod)
        } else if (model.ProductAddOnId) {
            this.addOns.push(model);
        }
    }
    onError(err) {
        this.loading = false;
        swal({
            position: 'top-end',
            type: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href>Why do I have this issue?</a>'
        });
    }

    // Modal General UI Controls
    validModel(): boolean {
        return this.model.IsValid();
    }
    close() {
        this.open = false;
        this.modalClose.emit(true);
    }
    save() {
        if (this.validModel()) {
            this.loading = true;
            if (this.reference.action === 'edit') {
                this.prodSrv.updatePriceList(this.model)
                    .subscribe(data => this.onEdit(data),
                        err => this.onError(err));
                this.updatePriceListItems();
            } else {
                this.prodSrv.createPriceList(this.model)
                    .subscribe(data => this.onCreate(data),
                        err => this.onError(err));
            }
        }
    }
    onEdit(data): boolean {
        this.loading = false;
        if (data['result']) {
            this.reference.action = 'edit';
            this.reference.object = PriceListModel.Parse(data['result']);
            this.model.Id = this.reference.object.Id;
            this.initAction();
            swal({
                position: 'top-end',
                type: 'success',
                title: 'Price list have been save',
                showConfirmButton: false,
                timer: 1500
            });
            return true;
        } else {
            swal({
                position: 'top-end',
                type: 'error',
                title: 'Oops...',
                text: data['errorMessage'],
                footer: '<a href>Why do I have this issue?</a>'
            });
            return false;
        }
    }
    onCreate(data) {
        this.loading = false;
        if (this.onEdit(data)) {
            this.products.forEach(x => {
                x.PriceListId = this.model.Id
                x.Id = 0
            });
            this.addOns.forEach(x => x.PriceListId = this.model.Id);
            this.updatePriceListItems();
            if (this.reference.action === 'copy') {
                this.reference.action = 'edit';
                this.title = `Edit ${this.model.Name} (${this.model.Id})`;
            }
        }
    }

    onExport(): void {
        if (this.products.length > 0) {
            let arrObj = [];
            this.products.forEach((e: PriceListItemModel) => {
                const { Id, PriceListId, ProductVersionId, PriceSheetName, Name, Sku, VersionName, PriceCode, CostCode, Cost, Margin, Price, DiscountPrice, IsActive

                } = { ...e }
                const obj = { Id, PriceListId, ProductVersionId, PriceSheetName, Name, Sku, VersionName, PriceCode, CostCode, Cost, Margin, Price, DiscountPrice, IsActive
                }
                arrObj.push(obj);
            });

            this.excelSvc.exportAsExcelFile(arrObj, 'PriceList');
        }
    }

    onImport(evt: any): void {
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            const dataUpload = <any[]>(XLSX.utils.sheet_to_json(ws, {
                header: ['Id', 'PriceListId', 'ProductVersionId',
                    'PriceSheetName', 'Name', 'Sku', 'VersionName', 'PriceCode', 'CostCode', 'Cost', 'Margin', 'Price', 'DiscountPrice', 'IsActive']
            }));
            const parseData: Array<PriceListItemModel> = [];
            for (let i = 1; i < dataUpload.length; i++) {
                parseData.push(new PriceListItemModel(dataUpload[i]['Id'], this.model.Id, dataUpload[i]['ProductVersionId'],
                    dataUpload[i]['ProductAddOnId'], dataUpload[i]['Name'], dataUpload[i]['Sku'], dataUpload[i]['VersionName'],
                    dataUpload[i]['PriceCode'], dataUpload[i]['CostCode'], dataUpload[i]['Price'],
                    dataUpload[i]['DiscountPrice'], dataUpload[i]['IsActive'], dataUpload[i]['Cost']));
            }
            evt.target.value = null;
            this.prodSrv.updatePriceListItems(parseData).subscribe(() => this.getPriceListItems(this.model.Id),
                err => this.onError(err));
        };
        reader.readAsBinaryString(target.files[0]);
    }

    highlightPChange(form: NgForm, i): string {
        return form.form.controls[`Price${i}`]?.dirty ? 'change-highlight' : 'mat-input';
    }

    highlightDPChange(form: NgForm, i): string {
        return form.form.controls[`DiscountPrice${i}`]?.dirty ? 'change-highlight' : 'mat-input';
    }

    onViewMore(): void {
        this.openPriceListAvailable = true;
        this.modalReference = new ModalTransferModel('edit', PricelistAvailableProductsModalComponent, this.model.Id, null, null);
    }

    onClosePriceListAvailableModal(data) {
        this.openPriceListAvailable = false;
        if (data) {
            this.products.push(...data);
        }
    }

    getPercent(price: number = 0, discountPrice: number = 0) {

        if (price === 0) {
            return 0;
        } else if (discountPrice === 0) {
            return 0
        } else {
            return ((discountPrice / price))
        }
    }
    editParams(obj: any, type: string, event: any) {
        let regex = /[$ € % ?]+/;
        let regex2 = /[, . .0 .00 ?]+/;
        let value = event.target.value;
        if (value.length === 0 || value === undefined) {
            value = 0;
        }
        obj.hasError = false;
        switch (type) {
            case 'price':
                if (regex.test(value)) {
                    value = value.replace(/([ $ € ])/g, '');
                }
                if (regex2.test(value)) {
                    value = value.replace(/([/,/g])/g, '');
                }
                if (this.getPercent(value, obj.DiscountPrice) < 0) {
                    obj.percent = 0
                } else if (value < obj.DiscountPrice) {
                    obj.hasError = true;
                    return;
                } else {
                    obj.percent = Number(this.getPercent(value, obj.DiscountPrice));
                }

                break;
            case 'discount':
                if (regex.test(value)) {
                    value = value.replace(/([ $ € ])/g, '');
                }
                if (regex2.test(value)) {
                    value = value.replace(/([/,/g])/g, '');
                }
                obj.Price = Number(value / (obj.percent));
                break;
            case 'percent':
                if (event.target.value > 0) {
                    obj.Price = Number(obj.DiscountPrice / ((event.target.value) / 100))
                } else {
                    obj.price = Number(obj.DiscountPrice);
                }

                break;

            default:
                break;
        }

    }
    onFocusOutEvent(event: any, obj: any, type: string) {
        let regex = /[$ € % ?]+/;
        let regex2 = /[, . .0 .00 ?]+/;
        let value = event.target.value;
        if (value.length === 0 || value === undefined) {
            value = 0;
        }
        switch (type) {
            case 'price':
                if (regex.test(value)) {
                    value = value.replace(/([ $ € ])/g, '');
                }
                if (regex2.test(value)) {
                    value = value.replace(/([/,/g])/g, '');
                }
                if (value < obj.DiscountPrice) {
                    value = Number(obj.DiscountPrice);
                    obj.percent = 100;
                    obj.hasError = false;
                }
                obj.Price = Number(value);
                break;
            case 'discount':
                if (regex.test(value)) {
                    value = value.substring(1, value.length)
                }
                obj.DiscountPrice = Number(value);
                break;
            case 'percent':
                if (regex.test(value)) {
                    value = value.substring(0, value.length - 1)
                    obj.percent = Number(value / 100);
                } else {
                    obj.percent = Number(value / 100);
                }

                break;

            default:
                break;
        }
    }

    createReport(event: any, obj: any) {
        if (event.target.value == 2) {
            let arrTitles = ['Id','PriceListId','ProductVersionId','PriceSheetName','Name','Sku','VersionName','PriceCode','CostCode','Cost','Margin','Price','DiscountPrice','IsActive'];
            let arrObj = [];
            this.exportService.exportDocument('xls', arrObj, arrTitles,'Report_');
        }
        else if (event.target.value == 1) {
            this.file.nativeElement.click();
        }
    }
    
}
