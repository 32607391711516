
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import { ConfigurationService } from '../../../../services/configuration.service';
import { LanguageModel } from '../../../../models/language.model';
import swal from 'sweetalert2';
import { AuthService, InternationalizationService } from '../../../../services';

@Component({
    selector: 'app-language-modal',
    templateUrl: './language-modal.component.html',
    styleUrls: ['./language-modal.component.scss']
})
/** CurrencyModal component*/
export class LanguageModalComponent implements OnInit {
    @Input() reference: ModalTransferModel;

    model: LanguageModel;

    editMode: boolean;
    isNew: boolean;
    title: string;
    canRemove = false;
    loading = false;

    @Input() set open(open: boolean) {
        this._open = open;
    }
    get open(): boolean {
        return this._open;
    }
    private _open: boolean;

    @Output() modalClose = new EventEmitter();


    constructor(public activeModal: NgbActiveModal, private srv: ConfigurationService, private auth: AuthService,
        private i18Svc: InternationalizationService) {
        this.i18Svc.initialize();
    }
    canView = this.auth.canViewLanguage;
    canEdit = this.auth.canEditLanguage;

    ngOnInit() {
        this.initMode();
    }

    initMode() {
        // Set Mode
        this.isNew = !this.reference || !this.reference.object;
        this.editMode = this.isNew;

        // Set Model
        if (!this.isNew) {
            this.model = this.reference.object;
        } else {
            this.model = LanguageModel.create();
            this.reference.object = this.model;
        }

        // Set Title
        if (this.isNew) {
            this.title = 'Add Language';
        } else {
            this.title = 'Language: ' + this.model.name;
        }
    }

    handleError(err) {
        console.log(err);
        this.loading = false;
        swal(' Ops...', ' Something went wrong', 'error');
    }

    validModel(): boolean {
        return true;
    }
    cancel() {
        this.editMode = false;
    }
    goEditMode() {
        this.editMode = true;
    }

    save() {
        if (this.validModel() && this.canEdit) {
            this.loading = true;
            if (this.isNew) {
                this.srv.languageCreate(this.model)
                    .subscribe(data => this.onResponse(data),
                        err => this.handleError(err));
            } else {
                this.srv.languageUpdate(this.model)
                    .subscribe(data => this.onResponse(data),
                        err => this.handleError(err));
            }
        }
    }
    onResponse(data) {
        if (!this.reference) {
            this.reference = new ModalTransferModel('open', LanguageModalComponent, null, null);
        }
        this.model = LanguageModel.parse(data['result']);
        this.reference.object = this.model;
        this.initMode();
        this.cancel();
        this.closeModal(true);
        this.loading = false;
    }
    close(swal: boolean = false, success: boolean = true) {
        this.reference.close();
        if (swal) {
            if (success) {
                this.reference.setSuccess('Language Added', 'your language have been added: ' + this.model.name);
            } else {
                this.reference.setError('Language Error', 'failed to add language: ' + this.model.name);
            }
        }
        this.closeModal();
    }

    closeModal(refresh?: boolean): void {
        this.open = false;
        this.modalClose.emit(refresh);
    }

}
