import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccountRegionModel } from '../models/account.model';
import { ClrDatagridSortOrder } from '@clr/angular';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  regionChange$ = new Subject();
  regionAllChange$ = new Subject();
  selectedRegionsChange$ = new Subject();

  loadingRegion$ = new Subject();

  set selectedRegions(selectedRegions: AccountRegionModel[]) {
    this._selectedRegions = selectedRegions;
  }
  _selectedRegions: AccountRegionModel[];

  get selectedRegions(): AccountRegionModel[] {
    return this._selectedRegions || [];
  }

  set allRegions(allRegions: AccountRegionModel[]) {
    this._allRegions = allRegions;
  }
  _allRegions: AccountRegionModel[];

  get allRegions(): AccountRegionModel[] {
    return this._allRegions || [];
  }

  languageId = 1;

  tenantId = '';
  tenantList: Array<{ name: string, guid: string }> = [];

  userRoles: Array<{ role: string, access: string[] }> = [
    {
      role: 'Internal-Credit', access: [
        'Account-View',
        'Credit-Admin',
        'Currency-View',
        'Language-View',
        'Product-Admin',
        'Region-View'
      ]
    },
    {
      role: 'Internal-Manager', access: [
        'Account-Admin',
        'Credit-Admin',
        'Currency-View',
        'IM-Admin',
        'Language-View',
        'Marketing-Admin',
        'Order-Admin',
        'PriceList-Admin',
        'Product-Admin',
        'Region-View',
        'Report-View',
        'Setting-Admin',
        'Store-User',
        'Tax-Admin',
        'Tax-View',
        'User-Admin',
        'User-View'
      ]
    },
    {
      role: 'Internal-Martketing', access: [
        'Account-View',
        'Currency-View',
        'Language-View',
        'Marketing-Admin',
        'Product-View',
        'Region-View'
      ]
    },
    {
      role: 'Internal-User', access: [
        'Account-View',
        'Credit-Admin',
        'Currency-View',
        'Language-View',
        'Order-Admin',
        'Region-View',
        'Report-View',
        'Store-User',
        'Tax-View'
      ]
    },
    {
      role: 'Internal-View Only', access: [
        'Account-View',
        'Credit-View',
        'Currency-View',
        'IM-View',
        'Language-View',
        'Order-View',
        'PriceList-View',
        'Product-View',
        'Region-View',
        'Setting-View',
        'Tax-View',
        'User-View'
      ]
    },
    {
      role: 'Reseller-User', access: [
        'Order-View',
        'Store-User'
      ]
    },
    { role: 'Reseller-View-Only', access: ['Order-View'] },
    { role: 'Super-Admin', access: ['DB-Admin'] }
  ];

  dataGridPageSize = 10;

  setDataGridPageSize(size: number) {
    this.dataGridPageSize = size;
  }

  init() {
    return new Promise<void>((resolve, _) => {
      try {
        this.tenantList = environment.tenantList || [];
        const localTenant = localStorage.getItem('tenant');
        let tier;
        if (localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser')).hasOwnProperty('tier')) {
          tier = JSON.parse(localStorage.getItem('currentUser'))?.tier;
        }
        if (tier === 0 && localTenant) {
          this.tenantId = this.tenantList.find(x => x.guid === localTenant)?.guid;
        }
        if (!this.tenantId) {
          if (environment.instanceID) {
            this.tenantId = environment.instanceID;
          } else if (this.tenantList?.length > 0) {
            this.tenantId = this.tenantList[0]?.guid || '';
          }
        }
        this.setTenant(this.tenantId);
        resolve();
      } catch (e) {
        console.log(e);
        resolve();
      }
    });
  }

  setTenant(tenantId: string): void {
    localStorage.setItem('tenant', tenantId);
  }

  onRegionChange() {
    this.regionChange$.next(null);
  }

  onLoadingRegionChange(regionId: number) {
    this.loadingRegion$.next(regionId);
  }

  onRegionAllChange(list) {
    this.allRegions = list;
    this.regionAllChange$.next(list || []);
  }

  selectedRegionsChange(list) {
    this.selectedRegions = list;
    this.selectedRegionsChange$.next(list || []);
  }

  hasError(form: NgForm, property: string): boolean {
    return this.hasDirty(form, property) && form.controls[property].errors.required;
  }

  hasDirty(form: NgForm, property: string): boolean {
    return form?.controls && form.controls[property]
      && (form.controls[property].invalid
        && (form.controls[property].dirty || form.controls[property].touched)
        && form.controls[property].errors !== null);
  }

  get userRoleList() {
    return this.userRoles;
  }

  currencyCode(purchaseCurrencyId: number): string {
    return purchaseCurrencyId === 2 ? 'EUR' : 'USD';
  }

  getDatagridColumnSortProperties(nameKey: string, orderKey: string, defaultName: string): { name: string, order: number } {
    let colunmName;
    let colunmOrder: number;
    try {
      colunmName = localStorage.getItem(nameKey);
      if (!colunmName) {
        colunmName = defaultName;
        colunmOrder = ClrDatagridSortOrder.ASC;
      } else {
        colunmOrder = JSON.parse(localStorage.getItem(orderKey));
        if (!colunmOrder) {
          colunmOrder = ClrDatagridSortOrder.ASC;
        }
      }
    } catch (x) {
      if(!colunmName) {
        colunmName = defaultName;
      }
      colunmOrder = ClrDatagridSortOrder.ASC;
    }
    return { name:  colunmName, order: colunmOrder}
  }

}
